.DashboardFirst{
    padding: 10px 20px;
}

.BemVindoTituloDashFirst{
    font-weight: 700!important;
    font-size: 20px!important;
    color: #212B36!important;
    margin-bottom: 15px!important;
}
.destivar{
    color: #FFF!important;
    font-size: 0.1px;
}
@media (max-width: 767px){
    .BemVindoTituloDashFirst{
       
        margin-bottom: 0px!important;
    }

}

.CardTopoAtendentes{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 15px;
}

.SlideDivFiltroAssuntos {
    pointer-events: none!important;
}


.NomeCardTopoPrincipalDashFirst{
    color: #fff;
    margin-top: 5px!important;
    font-weight: 700!important;
    font-size: 20px;
}
.itensAtendentesDashdiv{
    width: 100%;
    text-align: center;
}
.NomeCardTopoPrincipalDashFirstDesktop{
    color: #fff;
    margin-top: 5px!important;
    font-weight: 700!important;
    font-size: 20px;
    margin-left: 15px!important;
}

.CardTopoPrincipalDashFirst{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin-bottom: 15px!important;
}

.itensAtendentesDash{
    background-color: transparent!important;
}

.ItemsSobreMimCardTopoPrincipalDashFirstDesktop{
    margin: 15px 0!important;
}

.ItemsSobreMimCardTopoPrincipalDashFirstDesktop p{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
    margin-left: 10px!important;
}

.CarouselDashboardInicial{
    margin-bottom: 15px!important;
}

.FotoNomeDashFirst{
    background-image: url('./img/3.png') ;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 9rem;
}

.FotoNomeDashFirst p{
    margin-left: 0px!important;
}

.BotoesCardTopoPrincipalDashFirst{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin-top: 15px;
}

.BotoesCardTopoPrincipalDashFirstDesktop{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
}

.AlertDashAtendimentoProfissional svg{
    color: #fff;
}

.BuscaComoEstaSeSentindoDashInicialDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 25px;
    height: 125px;
    justify-content: space-between;
    margin: 15px 0px;
}


.BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao .btnAzul{
    background: #3366FF!important;
    border-radius: 8px!important;
    color: #fff!important;
    height: 40px;
    margin-right: 5px;
}

.BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao .btnBorderAzul{
    background: #fff!important;
    border: 1px solid #3366FF!important;
    border-radius: 8px!important;
    color: #3366FF!important;
    height: 40px;
    margin-left: 5px;
}

.btnBuscarAnjoCardTopoPrincipalDashFirst,
.btnAdicionarSaldoCardTopoPrincipalDashFirst{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
}

.TextoComplementarCardTopoPrincipalDashFirst{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #637381;
}

.ListadeAtendentesDashFirst .btnBorderAzul{
    border: 1px solid #3366FF!important;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    width: 100%;
}

.BotoesCardTopoPrincipalDashFirst .btnBorderAzul{
    border: 1px solid #3366FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    height: 6rem;
    width: 155px;
}

.BotoesCardTopoPrincipalDashFirstDesktop .btnBorderAzul{
    border: 1px solid #3366FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #3366FF;
    margin: 8px 0px;
    height: 6rem;
}

.CarrosselDashFirst{
    margin: 20px 0px;
    width: 100%;
    height: 10rem;
    background: #3366FF;
    border-radius: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.TituloInputBuscaporAnjoeFiltro{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #637381;
}
.imgslider{
    width: 100%!important;
}

.MeusAgendamentosDashInicial{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0px;
    background-image: url(./img/bg-agendamentos.png)!important;
    background-size: contain!important;
    background-repeat: no-repeat;
    background-position: right;
}

.AgendamentoRecorrenteNomeeFuncaoAnjo{
    margin-left: 15px;
}

.HeaderAgendamento{
    margin-top: 15px;
    padding: 10px 20px;
    background: #F4F6F8;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    text-align: left;
}

.HeaderAgendamento p{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #45515B!important;
}

.AgendamentosDashHeroiCard{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius:16px 16px;
}

.MeusAgendamentosDashInicialRecorrente{
    padding: 20px 15px;
    margin-bottom: 16px;
    background-image: url(./img/bg-agendamentos.png)!important;
    background-size: contain!important;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    border-radius: 16px;
}

.MeusAgendamentosDashInicialRemarcacao .BotaoMaisOpcoes{
    display: inline-flex;
    width: 100%;
}

.MeusAgendamentosDashInicialRemarcacao .AgendamentoRecorrenteFotoAnjo{
    display: inline-flex;
}



.MeusAgendamentosDashInicialRemarcacao{
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0px;
    background-color: #FFF5CC;
    width: 100%;
}

.AgendamentoRecorrenteNomeeFuncaoAnjo .FuncaoAnjoAgendamentoRecorrente{
    background: #26CFFF!important;
    border-radius: 8px!important;
    color: #fff!important;
    font-weight: 400!important;
    font-size: 12px!important;
    width: fit-content;
    padding: 2px 9px;
    margin-bottom: 5px;
}

.InputBuscaporAnjoeFiltro .MuiFormControl-root.css-15bjg8p{
    margin: 0;
}

.ListadeAtendentesDashFirst .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc {
    padding: 8px 0;
}

.FiltrarAssuntosDashInicial {
    margin-bottom: 10px;
}

.DataeHoraAgendamentoRecorrente, .DataAgendamentoRecorrente , .HoraAgendamentoRecorrente{
    display: inline-flex;
    width: 70%;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    color: #637381!important;
    margin-top: 5px;
    align-items: center;
}

.DataeHoraAgendamentoRecorrente{
    width: 100%;
}

.DataAgendamentoRecorrente p, .HoraAgendamentoRecorrente p{
    margin-left: 8px!important;
}

.AgendamentoRecorrenteNomeFuncaoeFoto{
    width: 100%;
    display: inline-flex;
}

.AgendamentoRecorrenteNomeeFuncaoAnjo .NomeAnjoAgendamentoRecorrente{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
}

.MeusAgendamentosDashInicial .TituloMeusAgendamentosDashInicial{
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #212B36;
    margin: 10px 0;
}

.MeusAgendamentosDashInicialRecorrente .TituloMeusAgendamentosDashInicial{
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #212B36;
    margin: 10px 0;
}

.InputBuscaporAnjoeFiltro .css-kn3umn-MuiInputBase-root-MuiOutlinedInput-root {
    width: 99%;
}

.FiltrarInputBuscaporAnjoeFiltro{
    cursor: pointer;
}

.BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao{
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.SobreMimCardTopoPrincipalDashFirstDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 20px 15px;
    margin: 15px 0px;
}

.CardMeusAgendamentosDashInicial .ButtonCardMeusAgendamentosDashInicial{
    background-color: #3366FF!important;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #fff!important;
    margin: 8px 0px;
    width: 200px;
}

.QtdAcolhimentos, .SaldoAtual{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 24px!important;
    line-height: 36px!important;
    text-align: center!important;
    color: #212B36!important;
}

.TituloSobreMimCardTopoPrincipalDashFirstDesktop{
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 28px!important;
    color: #212B36!important;
}

.DescricaoSobreMimCardTopoPrincipalDashFirstDesktop{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #212B36!important;
    margin: 20px 0!important;
}



.IconButtonCardMeusAgendamentosDashInicial{
    color: #fff;
}

.TituloFiltrarPorAssuntoDashFirst{
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #212B36;
    margin-bottom: 15px!important;
}

.MuiTabs-root.IconsFiltroBuscaAssunto.css-te4opc {
    height: 60px!important;
}

.CardAzulDashInicial{
    background: #3366ff;
    background-position: 50%;
    background-size: cover;
    border-radius: 16px;
    margin-top: 16px;
    margin-bottom: 10px;
    padding: 30px 40px;
}

.CardAzulDashInicial .TituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 600!important;
    font-size: 24px!important;
    line-height: 32px!important;
    width: 60vw;
}

.CardAzulDashInicial .SubtituloCardAzulDashInicial{
    color: #fff;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 22px!important;
    margin: 13px 0;
}

.CardAzulDashInicial .BtnCardAzulDashInicial{
    background: #FFFFFF;
    border-radius: 8px;
    color: #212B36;
    padding: 10.5px 14px;
    margin-left: 15px;
}



.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.itensAtendentesDash.css-1w11epi-MuiPaper-root{
    padding: 6px 0;
    width:100%;
}



.FiltrarAssuntosDashInicial .CardFiltrarAssunto{
    margin: 0px!important;
}


.CardAzulDashInicial .ImagemCardAzulDashInicial{
    margin-bottom: 10px;
    margin-top: -85px;
    width: 175px;
}

.ListadeAtendentesDashFirst .cardAtendente{
    margin:0px;
}

.ListadeAtendentesDashFirst .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc .css-1cdo4bs > :not(style) + :not(style) {
    margin: 0px 0px 0px 0px;
    position: absolute;
    right: 40px;
}


.ListadeAtendentesDashFirst .cardAtendente span.MuiBadge-root.css-1rzb3uu {
    height: fit-content;
}

.ListadeAtendentesDashFirst .cardAtendente .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1nky8vc button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.BtnBrancoAtendente.css-19gizus {
    margin: 0;
    width: 100%;
}

.inputValorCardAzulDashInicial{
    background: rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    color: #fff!important;
}

.inputValorCardAzulDashInicial .css-h51211-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border-color: #fff;
}

.inputValorCardAzulDashInicial .css-k865uu-MuiFormLabel-root-MuiInputLabel-root,
.inputValorCardAzulDashInicial .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input{
    color: #fff;
}

.inputValorCardAzulDashInicial .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10.5px 14px;
}

.CapaAtendenteDashFirstDesktop{
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    height: 25vh;
    margin-bottom: 2rem;
}



.CapaAtendenteDashFirstDesktopAzul{
    border-radius: 16px;
    background-image: url('./img/3.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
}

.FotoeNomeAtendenteDashFirstDesktop {
    display: inline-flex;
    align-items: center;
    margin-bottom: -15px;
    margin-left: 15px;
}

.DashFirstDesktop .CardMeusAgendamentosDashInicial{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
}


.ContribuirValoreBotao input#\:r82\: {
    height: 16px;
    color: #FFF;
}

.FiltrarAssuntosDashInicial .CardFiltrarAtendente{
    margin: 0px!important;
    margin-bottom: 15px!important;
    padding: 20px 25px;
}

@media (min-width: 768x) and (max-width: 992px){
    .FotoNomeDashFirst{
        height: 12rem!important;
    }

    .FotoNomeDashFirst .NomeCardTopoPrincipalDashFirstDesktop{
        margin-left: 0px!important;
    }
}


@media (max-width: 767px){
    .InputBuscaporAnjoeFiltro .css-kn3umn-MuiInputBase-root-MuiOutlinedInput-root {
        width: 97%;
    }

    

    .FiltrosDashboardInicial .FiltrarAssuntosDashInicial{
        margin-top: 0px;
    }

    .MuiFormControl-root.MuiTextField-root.inputValorCardAzulDashInicial.css-lavlqk-MuiFormControl-root-MuiTextField-root {
        width: 55%;
    }

    .FotoNomeDashFirst{
        background-image: url('./img/3.png');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 9rem;
    }

    .CardFiltrarAssunto .css-v3rcql-MuiTabs-root .MuiTabs-scrollButtons, 
    .CardFiltrarAssunto .css-te4opc .MuiTabs-scrollButtons{
        display: none;
    }
}

@media (max-width: 450px){
    .inputValorCardAzulDashInicial{
        width: 50%;
    }

    .CardAzulDashInicial .BtnCardAzulDashInicial{
        margin-left: 3%;
        width: 30%;
    }
}
