.cardAtendente{
    /* Background/Light/Paper */
    background: #FFFFFF;
    /* Shadows/Light/Card */
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 15px;
}

.ListadeAtendentesHeroi .cardAtendente{
    margin: 16px 0px;
}

.cardAtendente .css-hxrfoi-MuiStack-root {
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
}

.ItemAtendentePerfil span.MuiBadge-root.css-1rzb3uu {
    height: 40px;
}

.IndicadorTipoProfissionalAtendente{
    background: #26CFFF;
    border-radius: 8px;
    padding: 2px 9px;
    color: #fff;
    margin-bottom: 5px;
}

.cardAtendente .css-1cdo4bs > :not(style) + :not(style){
    margin: 0px!important;
}

.ItemAtendentePerfil span.MuiBadge-root.css-1c32n2y-MuiBadge-root {
    height: 45px;
}

.ItemAtendentePerfil, .AssuntosAtendente{
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.AssuntosAtendente #btnMaisAssuntos{
    width: 55px!important;
    height: fit-content;
    padding: 4px 0px!important;
}

.NomeETemasAtendente{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
    text-transform: capitalize!important;
}

.NomeETemasAtendente .NomeDoAtendente div{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #45515B;
    text-align: left;
    width: 100%;
    text-transform: capitalize!important;
}

.chipTipoAtendenteAnjo{
    background-color: #26cfff!important;
    color: #fff!important;
}

.chipTipoAtendentePsicologo{
    background-color: rgb(118, 53, 220)!important;
    color: #fff!important;
}

.chipTipoAtendenteTerapeuta{
    background-color: #ffbe98!important;
    color: #fff!important;
}

.chipTipoAtendenteCoach{
    background-color: #5dbfc0!important;
    color: #fff!important;
}

.chipTipoAtendenteAnjoborder{
    border-color: #26cfff!important;
    color: #26cfff!important;
}

.chipTipoAtendentePsicologoborder{
    border-color: rgb(118, 53, 220)!important;
    color: rgb(118, 53, 220)!important;
}

.chipTipoAtendenteTerapeutaborder{
    border-color: #ffbe98!important;
    color: #ffbe98!important;
}

.chipTipoAtendenteCoachborder{
    border-color: #5dbfc0!important;
    color: #5dbfc0!important;
}


.FotoPerfilAtendente{
    height: min-content;
}

.AvaliacaoAtendente{
    margin-top: 10px;
}

.AvaliacaoAtendimento {
    display: inline-flex;
    align-content: flex-end;
    align-items: flex-end;
}

.AvaliacaoAtendenteTexto{
    font-style: normal;
    font-weight: 400;
    font-size: 12px!important;
    line-height: 22px;
    color: #637381;
    display: inline-flex;
    margin-left: 10px!important;
}

.cardAtendente .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.css-1w11epi-MuiPaper-root {
    padding: 0;
}

.AvaliacaoAtendimento .css-17ceore-MuiSvgIcon-root{
    width: 0.75em;
    height: 0.75em;
}

.ValorETempoAtendimento .ValorAtendimento{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #45515B;
}

.ValorETempoAtendimento .TempoAtendimento {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #637381;

}

.AssuntosAtendente .Assunto, .AssuntosAtendente .MaisAssuntos{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #36f;
    border: 1px solid #36f;
    border-radius: 6px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.AssuntosAtendente .Assunto{
    padding: 3px 6px!important;
    width: max-content;
}

.AssuntosAtendente .MaisAssuntos{
    font-weight: 600!important;
    padding: 3px 3px!important;
    width: min-content!important;
}

.BotoesAcao{
    margin-top: 20px!important;
}

@media (max-width: 385px){
    .BotoesAcao button {
        font-size: 12px!important;
        padding: 6px 0px!important;
    }
}



.ItemAtendentePerfil {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.chipPrimeiroAtendimentoGratis{
    margin-bottom: .5rem;
}

.FotoeNomeAtendente {
    display: flex;
}

.ValorETempoAtendimento {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Exibe os 3 primeiros filhos */
.AssuntosAtendente > .Assunto:nth-child(-n+3) {
    display: block;
  }
  
  /* Oculta os demais filhos */
  .AssuntosAtendente > .Assunto:nth-child(n+4) {
    display: none;
  }

 /* Oculta a div .MaisAssuntos por padrão */
.MaisAssuntos {
    display: none!important;
  }
  
  /* Exibe a div .MaisAssuntos quando .AssuntosAtendente tem mais de 3 filhos .Assunto */
  .AssuntosAtendente > .Assunto:nth-child(n+4) ~ .MaisAssuntos {
    display: flex!important;
  }

@media (min-width: 768px){
    .NomeETemasAtendente .NomeDoAtendente{
        width: 100%;
        text-transform: capitalize!important;
    }

    .NomeETemasAtendente .NomeDoAtendente div{
        text-transform: capitalize!important;
    }
}

@media (max-width: 767px){
    .ListadeAtendentesHeroi .cardAtendente{
        margin: 0px!important;
        margin-bottom: 20px!important;
    }
}

@media (max-width: 480px){
    .cardAtendente .ValorTempoAtendimentoItem{
        padding: 0px;
    }

    .NomeETemasAtendente{
        margin-left: 10px;
        width: 100%!important;
        text-transform: capitalize!important;
    }

    .NomeETemasAtendente .NomeDoAtendente div{
        text-transform: capitalize!important;
    }

    .FotoeNomeAtendente{
        width: 75%;
    }

    .ValorETempoAtendimento{
        width: 25%!important;
    }
}